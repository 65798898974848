import axios from "axios";
import config from "./config";
import { userLogout } from "./common";

let apiBasePath = "";

if (process.env.NODE_ENV === "production") {
  apiBasePath = config.API_BASEPATH_PROD;
}
if (process.env.NODE_ENV === "development") {
  apiBasePath = config.API_BASEPATH_SANDBOX;
}

class Request {
  constructor(dispatch, successFn, errorFn) {
    this.successFn = typeof successFn === "function" ? successFn : () => {};
    this.errorFn = typeof errorFn === "function" ? errorFn : () => {};
    this.dispatch = typeof dispatch === "function" ? dispatch : () => {};
  }

  /**
   * GET axios instance and do things that are common for every request
   */
  instance() {
    const headers = {};

    //if (process.env.NODE_ENV === "development") {
    //  headers.frontEnv = "local";
    //}
    const auth = localStorage.getItem("webAuthToken");
    if (auth) {
      headers.Authorization = auth;
    }
    const instance = axios.create({
      baseURL: "",
      headers,
    });

    return instance;
  }

  /**
   * GET Requests
   * @param {string} url
   * @param {object} params
   */
  async get(url, params = {}) {
    if (process.env.NODE_ENV === "production") {
      apiBasePath = config.API_BASEPATH_PROD;
    }
    if (process.env.NODE_ENV === "development") {
      apiBasePath = config.API_BASEPATH_SANDBOX;
    }
    const prod2Apis = [];
    const prod3Apis = [config.apis.payment.CC_BANK_LIST, config.apis.user.ACTIVE_SERVICES];

    if (process.env.NODE_ENV === "production" && prod2Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_PROD_2;
    }
    if (process.env.NODE_ENV === "development" && prod2Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_SANDBOX_2;
    }
    if (process.env.NODE_ENV === "production" && prod3Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_PROD_3;
    }
    if (process.env.NODE_ENV === "development" && prod3Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_SANDBOX_3;
    }
    try {
      const res = await this.instance().get(apiBasePath + url, params);
      const data = res.data || null;
      const headers = res.headers || null;
      this.successFn(data, headers);
      return res;
    } catch (error) {
      // const data = error.hasOwnProperty('data') ? data : null;
      // const headers = error.hasOwnProperty('headers') ? headers : null;

      if (error && error.response && error.response.status === 401) {
        userLogout();
        window.location.href = "/";
      }

      this.errorFn(error);
    }
  }

  /**
   * POST Requests
   * @param {string} url
   * @param {object} params
   */
  async post(url, params = {}) {
    if (process.env.NODE_ENV === "production") {
      apiBasePath = config.API_BASEPATH_PROD;
    }
    if (process.env.NODE_ENV === "development") {
      apiBasePath = config.API_BASEPATH_SANDBOX;
    }
    const prod2Apis = [config.apis.reporting.TRANSACTION_DETAIL];
    const prod3Apis = [config.apis.payment.BENI_LIST, config.apis.payment.BIN_CHECKER];

    if (process.env.NODE_ENV === "production" && prod2Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_PROD_2;
    }
    if (process.env.NODE_ENV === "development" && prod2Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_SANDBOX_2;
    }
    if (process.env.NODE_ENV === "production" && prod3Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_PROD_3;
    }
    if (process.env.NODE_ENV === "development" && prod3Apis.includes(url)) {
      apiBasePath = config.API_BASEPATH_SANDBOX_3;
    }
    try {
      const res = await this.instance().post(apiBasePath + url, params);
      const { data = {}, status } = res;
      this.successFn(data, status);
      return res;
    } catch (error) {
      const {
        response: { data = {}, status },
      } = error;
      this.errorFn(data, status);
    }
  }

  /**
   * PUT Requests
   * @param {string} url
   * @param {object} params
   */

  async put(url, params = {}) {
    if (process.env.NODE_ENV === "production") {
      apiBasePath = config.API_BASEPATH_PROD;
    }
    if (process.env.NODE_ENV === "development") {
      apiBasePath = config.API_BASEPATH_SANDBOX;
    }
    try {
      const res = await this.instance().put(apiBasePath + url, params);
      const { data = {}, status } = res;
      this.successFn(data, status);
      return res;
    } catch (error) {
      const {
        response: { data = {}, status },
      } = error;
      this.errorFn(data, status);
    }
  }

  /**
   * DELETE Requests
   * @param {string} url
   * @param {object} params
   */
  async delete(url, params = {}) {
    if (process.env.NODE_ENV === "production") {
      apiBasePath = config.API_BASEPATH_PROD;
    }
    if (process.env.NODE_ENV === "development") {
      apiBasePath = config.API_BASEPATH_SANDBOX;
    }
    try {
      const res = await this.instance().delete(apiBasePath + url, params);
      const { data = {}, status } = res;
      this.successFn(data, status);
      return res;
    } catch (error) {
      //handle error
      const {
        response: { data = {}, status },
      } = error;
      this.errorFn(data, status);
    }
  }
}

export default Request;
