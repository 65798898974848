import React from "react";
import TICKET from "../../assets/images/TICKET.png";
import BBPS from "../../assets/images/BBPS.png";
import DASHBOARD from "../../assets/images/DASHBOARD.png";
import DIGITAL_ACCOUNT from "../../assets/images/DIGITAL_ACCOUNT.png";
import MANAGE_ACCOUNT from "../../assets/images/MANAGE_ACCOUNT.png";
import TRANSACTION from "../../assets/images/TRANSACTION.png";
import QR_CODE from "../../assets/images/QR_CODE.png";
import { Icon } from "../styledConstants";

export const navData = [
  {
    icon: <Icon src={DASHBOARD} />,
    link: "/dashboard",
    title: "Dashboard",
    id: "DASHBOARD",
    expanded: false,
    subNav: [],
  },

  {
    icon: <Icon src={TRANSACTION} />,
    link: "/transactions",
    title: "Transactions",
    id: "TRANSACTION",
    expanded: false,
    subNav: [],
  },
  {
    icon: <Icon src={QR_CODE} />,
    link: "/my-qrcode",
    title: "My QR Code",
    id: "MY_QR",
    expanded: false,
    subNav: [],
  },

  {
    icon: <Icon src={DIGITAL_ACCOUNT} />,
    link: "/my-digital-account",
    title: "My Digital Account",
    id: "MY_VA",
    expanded: false,
    subNav: [],
  },

  // {
  //   icon: <IconSettings />,
  //   link: "#",
  //   title: "Settings",
  //   id: "SETTINGS",
  // },
  {
    icon: <Icon src={TICKET} />,
    link: "/manage-tickets",
    title: "Manage Tickets",
    id: "MANAGE_TICKETS",
    expanded: false,
    active: false,
  },
  {
    icon: <Icon src={MANAGE_ACCOUNT} />,
    link: "/manage-accounts",
    title: "Manage Accounts",
    id: "MANAGE_ACCOUNTS",
    expanded: false,
    active: false,
  },
  {
    icon: <Icon src={BBPS} />,
    link: "/manage-vas",
    title: "Recharge & Bill Payments",
    id: "MANAGE_VAS",
    expanded: false,
    active: false,
  },
];
