import { userConstants } from '../../actions/user';

const initialState ={
  user:{},
  successMessagse:"",
  errorMessage:"",
  currentScreen:"",
  nextPage:""
};

export default (state=initialState, action) => {
  switch (action.type) {
   
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        successMessagse: action.payload
      };
    case userConstants.LOGIN_FAIL:
      return {
        ...state,
        loggedIn: false,
        errorMessage: action.payload
      };
    case userConstants.GET_PROFILE:
      return {
        ...state,
        loggedIn: false,
        user: action.payload
      };
    case userConstants.ACTIVE_SCREEN:
      return {
        ...state,
        loggedIn: false,
        currentScreen: action.payload
      };
    case userConstants.NEXT_PAGE:
      return {
        ...state,
        loggedIn: false,
        nextPage: action.payload
      };      
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
