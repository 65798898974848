export default {
  API_BASEPATH_PROD: "https://x9fuaxn2f4.execute-api.ap-south-1.amazonaws.com/production",
  API_BASEPATH_PROD_2: "https://u41lwnnry6.execute-api.ap-south-1.amazonaws.com/production_2",
  API_BASEPATH_PROD_3: "https://5u5tqxh0p8.execute-api.ap-south-1.amazonaws.com/production_3",

  API_BASEPATH_SANDBOX: "https://sandboxapi1.imoneypay.in",
  API_BASEPATH_SANDBOX_2: "https://sandboxapi2.imoneypay.in",
  API_BASEPATH_SANDBOX_3: "https://sandboxapi3.imoneypay.in",

  apis: {
    user: {
      LOGIN: "/user-service/web/merchant/generate-otp",
      VERIFY_OTP: "/user-service/user/verify-otp",
      RESEND_OTP: "/user-service/web/merchant/generate-otp",
      GET_PROFILE: "/user-service/user/profile",
      STATE_LIST: "/user-service/admin/state/list",
      CITY_LIST: "/user-service/admin/city/list",
      UPDATE_PROFILE: "/user-service/admin/update/details",
      LOGOUT_USER: "",
      GET_PERMISSIONS: "/user-service/manage/user/permission",
      CREATE_TICKET: "/user-service/merchant/ticket/create",
      UPDATE_TICKET: "/user-service/merchant/ticket/update",
      TICKET_LIST: "/user-service/merchant/ticket/list",
      TICKET_CATEGORY_LIST: "/user-service/merchant/ticket/category",
      TICKET_STATUS_LIST: "/user-service/merchant/ticket/status",
      TICKET_PRIORITY_LIST: "/user-service/merchant/ticket/priority",
      TICKET_REQUESR_TYPE_LIST: "/user-service/merchant/ticket/request-type",
      USER_LIST: "/user-service/admin/user-list",
      CLOSE_ACCOUNT: "/user-service/user/delete-profile",
      ACTIVE_SERVICES: "/user-service/dashboard/webEnabled",
    },
    payment: {
      GET_PAYMENT_LISTS: "/payment-service/transaction/listing",
      QR_CODE: "/payment-service/qrcode/transaction",
      BANK_DETAIL: "/payment-service/beneficiary/bank/account/list",
      SELF_BANK_DETAIL: "/payment-service/user/bank/account",
      SETTLEMENT_REPORT: "/payment-service/payout/settlement-report",
      GET_WALLET: "/payment-service/payment/find-wallet",
      GET_VIRTUAL_ACCOUNT: "/payment-service/bank/get-virtual-account",
      GET_REFUND_LISTS: "/payment-service/payment/get-payu-transaction-detail",
      INITIATE_REFUND: "/payment-service/payment/initiate-refund",
      GET_REFUND_STATUS: "/payment-service/payment/fetch-refund-status",
      SEARCH_OPERATOR: "/payment-service/api/operator",
      ZONE_LIST: "/payment-service/api/zone/list",
      RECHARGE_PLAN: "/payment-service/recharge/plan",
      BIN_CHECKER: "/payment-service/credit-card/binChecker",
      BANK_LIST: "/payment-service/user/bank/list",
      VERIFY_ACCOUNT: "/payment-service/bank/penny-drop",
      ADD_BENI: "/payment-service/beneficiary/bank/account/add",
      BENI_LIST: "/payment-service/web/beneficiary/bank/account/list",
      FETCH_BENI: "/payment-service/beneficiary/bank/account/find",
      DELETE_BENI: "/payment-service/beneficiary/bank/account/remove",
      PAYMENT_INITIALIZE: "/payment-service/payment/request/handler",
      DIGITAL_ACCOUNT: "/payment-service/bank/get-virtual-account",
      TXN_RESPONSE: "/payment-service/transaction/detail/by/",
      CC_BANK_LIST: "/payment-service/user/bank/list/cc",
      BBPS_TYPE: "/payment-service/bill/category-details",
      BBPS_CONFIG: "/payment-service/bill/config",
      BBPS_BILL_DETAIL: "/payment-service/bill/detail",
      BBPS_PAY_BILL: "/payment-service/bill/pay",
      MOBILE_RECHARGE: "/payment-service/recharge/mobileNumber",
      CALCULATE_MARGIN: "/payment-service/api/operator/calculateMargin",
      SELF_ACCOUNT: "/payment-service/user/bank/account/",
      ADD_SELF_ACCOUNT: "/payment-service/user/bank/account/create",
      PG_CONFIG: "/payment-service/pgconfig/transfer-money/calculate",
    },
    reporting: {
      GET_DASHBOARD_DETAILS: "/reporting-service/merchant/dashboard/details",
      GET_DASHBOARD_GRAPH: "/reporting-service/merchant/dashboard/graph",
      TRANSACTION_DETAIL: "/reporting-service/report/transaction/details",
      RECHARGE_TYPE: "/reporting-service/payment/recent/transaction",
      TRANSACTION_LIST: "/reporting-service/payment/recent/transaction?transactionMode=",
    },
  },
};
