import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "@firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  set,
  update,
  push,
  child,
  orderByKey,
  orderByChild,
  orderByValue,
  query,
  equalTo,
  endAt,
  startAt,
  get,
} from "firebase/database";
const firebaseConfig = {
  databaseURL: "https://imoney-prod-24cf7-default-rtdb.firebaseio.com",
  apiKey: "AIzaSyBc3BGkFzv3yVajHRGSUxJ7_2Tf58qXqWE",
  authDomain: "imoney-prod-24cf7.firebaseapp.com",
  projectId: "imoney-prod-24cf7",
  storageBucket: "imoney-prod-24cf7.appspot.com",
  appId: "1:189639256324:android:e7b5d5aec3bace8cdb0851",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
export {
  ref,
  onValue,
  set,
  update,
  push,
  child,
  orderByKey,
  orderByChild,
  orderByValue,
  query,
  equalTo,
  endAt,
  startAt,
  database,
  auth,
  signInAnonymously,
  get,
};
