import { API_CONSTANTS } from "../../actions/wallet";

const initialState = {
  wallet: [],
  loading: false,
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case API_CONSTANTS.FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case API_CONSTANTS.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        wallet: action.payload,
      };
    case API_CONSTANTS.FETCH_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
