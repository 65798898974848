import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthLayout from "../components/Layout/authLayout";
import { getUser } from "../utils/common";
import { navData } from "../components/Navigation/constants";
import config from "../../src/utils/config";
import Request from "../utils/Request";
import { useSelector } from "react-redux";

export default function RequireAuth({ children }) {
  const { user } = useSelector((state) => state.user);
  const [permissionsArray, setPermissionsArray] = useState([]);

  const pathArray = [
    "/account/add",
    "/ticket/add",
    "/ticket/edit",
    "/transfer-fund",
    "/txn/response/",
    "/manage-vas",
    "/profile",
    "/notifications",
    "/transactions/invoice/",
  ];
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (!user.userType) {
      return;
    }
    const submitSuccess = (res) => {
      if (res.code === "0000") {
        const permissionsCodesArr = [];
        if (res.data) {
          Object.keys(res.data).forEach((key) => {
            const permissionArrayOfObject = res.data[key];
            permissionArrayOfObject.forEach((item) => {
              if (item.enable) {
                permissionsCodesArr.push(item.permissionCode);
              }
            });
          });
        }

        setPermissionsArray(permissionsCodesArr);
      }
    };
    const submitError = (res) => {};

    const api = new Request("", submitSuccess, submitError, false);
    return api.get(`${config.apis.user.GET_PERMISSIONS}?roleCode=MI`);
  }, [user]);

  const checkPermission = (pathName) => {
    return navData.some((item) => {
      if (pathName == item.link) {
        return permissionsArray.includes(item.id);
      }
      if (item.subNav) {
        const link = item.subNav.some((sub) => {
          return sub.link == pathName;
        });

        if (link) {
          return permissionsArray.includes(item.id);
        }
      } else {
        return false;
      }
    });
  };
  const checkSubPathPermission = (pathName) => {
    return pathArray.some((item) => {
      if (pathName.includes(item)) {
        return true;
      } else {
        return false;
      }
    });
  };

  let isAuth = true;
  if (getUser()) {
    isAuth = true;
  }

  if (!isAuth) {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }
  if (checkSubPathPermission(pathName)) {
    return <AuthLayout>{children}</AuthLayout>;
  }
  if (checkPermission(pathName)) {
    return <AuthLayout>{children}</AuthLayout>;
  }
  return <Navigate to="/dashboard" replace state={{ path: location.pathname }} />;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
