import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import RouteComponent from "./routes";
import { getUserProfile } from "./store/actions/user";
import { getUser } from "./utils/common";
import LogoutPopup from "./utils/useAutoLogout";
import { fetchWalletBalance } from "./store/actions/wallet";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checklogouttime = LogoutPopup();
  const location = useLocation();

  useEffect(() => {
    if (getUser()) {
      dispatch(getUserProfile());
      dispatch(fetchWalletBalance());
    } else {
      if (!location.pathname.includes("invoice") && !location.pathname.includes("digio")) {
        navigate("/");
      }
    }
  }, []);

  return <RouteComponent />;
};

export default App;
