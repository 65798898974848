import Request from "../../../utils/Request";
import Config from "../../../utils/config";
export const userConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  GET_PROFILE: "GET_PROFILE",
  ACTIVE_SCREEN: "ACTIVE_SCREEN",
  NEXT_PAGE: "NEXT_PAGE",
};

const loginSuccess = (data) => ({
  type: userConstants.LOGIN_SUCCESS,
  payload: data,
});

const LoginFail = (data) => ({
  type: userConstants.LOGIN_FAIL,
  payload: data,
});

const getProfile = (data) => ({
  type: userConstants.GET_PROFILE,
  payload: data,
});
const setActiveScreen = (data) => ({
  type: userConstants.ACTIVE_SCREEN,
  payload: data,
});
const setNextPage = (data) => ({
  type: userConstants.NEXT_PAGE,
  payload: data,
});

export const loginUser = (formData) => {
  return function (dispatch) {
    const successHandler = (res) => {
      if (res) {
        if (res) {
          if (res.success == false) {
            dispatch(LoginFail(res.msg));
          } else {
            // need to remove this code, once the cookie logic is ready at backend
            let isFirstTimeLogin = false;
            if (isFirstTimeLogin) {
              dispatch(setNextPage("reset"));
            } else {
              if (res && res.data && res.data.authToken) {
                localStorage.setItem("webAuthToken", res.data.authToken);
                dispatch(setActiveScreen("OTP_SCREEN"));
              } else {
                dispatch(LoginFail(res?.msg));
              }
            }
          }
        }

        dispatch(loginSuccess(res?.data?.msg));
      }
    };
    const errorHandler = (res) => {
      dispatch(LoginFail(res?.data?.msg));
    };
    const api = new Request("", successHandler, errorHandler, false);
    return api.post(`${Config.apis.admin.LOGIN}`, formData);
  };
};

export const getUserProfile = () => {
  return function (dispatch) {
    const successHandler = (res) => {
      if (res) {
        dispatch(getProfile(res?.data));
      }
    };
    const errorHandler = (res) => {};
    const api = new Request("", successHandler, errorHandler, false);
    return api.get(`${Config.apis.user.GET_PROFILE}`);
  };
};

export const logout = () => {
  return function (dispatch) {
    dispatch(getProfile({}));
  };
};
