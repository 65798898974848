import config from "../../../utils/config";
import Request from "../../../utils/Request";

export const API_CONSTANTS = {
  FETCH_DATA_REQUEST: "FETCH_DATA_REQUEST",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAIL: "FETCH_DATA_FAIL",
};

const fetchDataRequest = () => ({
  type: API_CONSTANTS.FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
  type: API_CONSTANTS.FETCH_DATA_SUCCESS,
  payload: data,
});

const fetchDataFail = (error) => ({
  type: API_CONSTANTS.FETCH_DATA_FAIL,
  payload: error,
});

export const fetchWalletBalance = () => {
  return function (dispatch) {
    dispatch(fetchDataRequest());

    const successHandler = (res) => {
      dispatch(fetchDataSuccess(res.data));
    };

    const errorHandler = (error) => {
      dispatch(fetchDataFail(error.message));
    };

    const api = new Request("", successHandler, errorHandler, false);
    return api.get(`${config.apis.payment.GET_WALLET}`);
  };
};
