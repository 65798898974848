import React, { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import lazyLoad from "./lazyLoad";
import RequireAuth from "./RequireAuth";

const Login = lazyLoad(lazy(() => import("../Pages/Login")));
const DashboardPage = lazyLoad(lazy(() => import("../Pages/Dashboard")));
const Merchants = lazyLoad(lazy(() => import("../Pages/Merchants")));
const Transactions = lazyLoad(lazy(() => import("../Pages/Transactions")));
const InvoiceDetail = lazyLoad(lazy(() => import("../Pages/Transactions/invoiceDetail")));
const Refunds = lazyLoad(lazy(() => import("../Pages/Refunds")));
const MyQrCode = lazyLoad(lazy(() => import("../Pages/MyQrCode")));
const Notifications = lazyLoad(lazy(() => import("../Pages/Notifications")));
const CreateTickets = lazyLoad(lazy(() => import("../Pages/Tickets/add")));
const Tickets = lazyLoad(lazy(() => import("../Pages/Tickets")));
const DigioCallback = lazyLoad(lazy(() => import("../Pages/MobileApp/DigioCallback")));
const PendingScreen = lazyLoad(lazy(() => import("../Pages/MobileApp/PendingScreen")));
const AddAccount = lazyLoad(lazy(() => import("../Pages/ManageAccounts/add")));
const BankTransfer = lazyLoad(lazy(() => import("../Pages/ManageAccounts/bankTransfer")));
const TransactionDetails = lazyLoad(lazy(() => import("../Pages/ManageAccounts/txnResponse")));
const AccountList = lazyLoad(lazy(() => import("../Pages/ManageAccounts")));
const DigitalAccount = lazyLoad(lazy(() => import("../Pages/MyDigitalAccount")));
const ManageVas = lazyLoad(lazy(() => import("../Pages/Vas")));

function RouteComponent() {
  return (
    <Routes>
      <Route path="/mobile/digio/callback" element={<DigioCallback />} />
      <Route path="/mobile/digio/pending" element={<PendingScreen />} />
      <Route path="/" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />
      <Route
        path="/refunds"
        element={
          <RequireAuth>
            <Refunds />
          </RequireAuth>
        }
      />
      <Route
        path="/transactions"
        element={
          <RequireAuth>
            <Transactions />
          </RequireAuth>
        }
      />
      <Route path="/transactions/invoice/:txn_no/:mode" element={<InvoiceDetail />} />
      <Route path="/transactions/invoice/:txn_no/:mode/:token" element={<InvoiceDetail />} />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Merchants />
          </RequireAuth>
        }
      />
      <Route
        path="/my-qrcode"
        element={
          <RequireAuth>
            <MyQrCode />
          </RequireAuth>
        }
      />
      <Route
        path="/notifications"
        element={
          <RequireAuth>
            <Notifications />
          </RequireAuth>
        }
      />
      <Route
        path="/manage-tickets"
        element={
          <RequireAuth>
            <Tickets />
          </RequireAuth>
        }
      />
      <Route
        path="/ticket/add"
        element={
          <RequireAuth>
            <CreateTickets />
          </RequireAuth>
        }
      />

      <Route
        path="/ticket/edit/:ticketId"
        element={
          <RequireAuth>
            <CreateTickets />
          </RequireAuth>
        }
      />

      <Route
        path="/manage-accounts"
        element={
          <RequireAuth>
            <AccountList />
          </RequireAuth>
        }
      />
      <Route
        path="/account/add/:self"
        element={
          <RequireAuth>
            <AddAccount />
          </RequireAuth>
        }
      />

      <Route
        path="/transfer-fund/:id"
        element={
          <RequireAuth>
            <BankTransfer />
          </RequireAuth>
        }
      />

      <Route
        path="/txn/response/:txn_no"
        element={
          <RequireAuth>
            <TransactionDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/my-digital-account"
        element={
          <RequireAuth>
            <DigitalAccount />
          </RequireAuth>
        }
      />

      <Route
        path="/manage-vas"
        element={
          <RequireAuth>
            <ManageVas />
          </RequireAuth>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default RouteComponent;
