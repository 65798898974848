import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Wrapper, Nav } from "./style";
import logo from "../../assets/images/logo.svg";
import { Icon } from "../styledConstants";
import NavList from "./NavList";
import { navData } from "./constants";
import Request from "../../utils/Request";
import Config from "../../utils/config";

export default function Navigation() {
  const [navigationData, setNavigationData] = useState([...navData]);
  const toggleNavigation = (e, isExpanded, index) => {
    e.preventDefault();
    const temp = [...navigationData];
    temp[index].expanded = !isExpanded;
    setNavigationData(temp);
  };

  useEffect(() => {
    const submitSuccess = (res) => {
      if (res.code === "0000") {
        let navigationDataCopy = [...navigationData];
        const permissionsCodesArr = [];
        if (res.data) {
          Object.keys(res.data).forEach((key) => {
            const permissionArrayOfObject = res.data[key];
            permissionArrayOfObject.forEach((item) => {
              if (item.enable) {
                permissionsCodesArr.push(item.permissionCode);
              }
            });
          });
        }
        navigationDataCopy.map((item) => (item.active = permissionsCodesArr.includes(item.id)));
        setNavigationData(navigationDataCopy);
      }
    };
    const submitError = (res) => {};

    const api = new Request("", submitSuccess, submitError, false);
    return api.get(`${Config.apis.user.GET_PERMISSIONS}?roleCode=MI`);
  }, []);

  return (
    <Wrapper>
      <Link className="logo" to="/">
        <Icon src={logo} width="141px" alt="" />
      </Link>
      <Nav>
        <NavList navData={navigationData} toggleNavigation={toggleNavigation} className="navlist" />
      </Nav>
    </Wrapper>
  );
}
