import { combineReducers } from "redux";
import alert from "../reducers/alert";
import user from "../reducers/user";
import wallet from "./wallet";

export default combineReducers({
  alert,
  user,
  wallet,
});
