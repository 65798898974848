import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Announcement, UserInfo, Wrapper } from "./style";
import iconAnnouncement from "../../../assets/images/icon-announcement.png";
import { Box, Icon, Text, ButtonSolid, ButtonOutline } from "../../styledConstants";
import { userLogout } from "../../../utils/common";
import IconLogout from "../../../assets/images/IconLogout";
import WALLET_NEW from "../../../assets/images/WALLET_NEW.png";
import { ref, onValue, orderByChild, query, equalTo, signInAnonymously, auth, database } from "../../../utils/firebase";
import { fetchWalletBalance } from "../../../store/actions/wallet";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const [notificationLists, setNotificationLists] = useState({});
  const { user } = useSelector((state) => state.user);
  const { wallet } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const notificationsRef = ref(database, `notifications/${user.userUuid}`);
      const queryConstraints = [orderByChild("read"), equalTo(false)];
      const dataSnapshot = query(notificationsRef, ...queryConstraints);
      signInAnonymously(auth).then(() => {
        onValue(
          dataSnapshot,
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              if (data) {
                dispatch(fetchWalletBalance());
                Object.keys(data).forEach((key) => {
                  if (!data[key].platform.includes("web")) {
                    delete data[key];
                  }
                });
              }
              setNotificationLists(data);
            } else {
              setNotificationLists({});
            }
          },
          (error) => {
            return error;
          },
        );
      });
    }
  }, [user]);

  const logout = () => {
    userLogout();
    navigate("/");
  };

  const profile = () => {
    navigate("/profile");
  };
  const notifications = () => {
    if (notificationLists) {
      Object.keys(notificationLists).forEach((notifiKey) => {
        let notificationData = notificationLists[notifiKey];
        notificationData.read = true;

        if (notificationData.isRead) {
          notificationData.isRead = true;
        }
        const updates = {};
        updates["notifications/" + user.userUuid + "/" + notifiKey] = notificationData;
        // update(ref(db), updates);
      });
    }
    navigate("/notifications");
  };
  return (
    <Wrapper className={`flex flex-center ${isDashboard ? "justify-end" : "space-between"} header hide-in-print`}>
      {!isDashboard && (
        <div className="flex flex-center gap10">
          <Text size="md">Wallet Balance </Text>
          <Icon src={WALLET_NEW} alt="Wallet icon" width="23px" />
          <Text className="number ml8" size="lg" color="color9">
            ₹
          </Text>
          <Text size="md" color="color9" className="">
            {wallet?.balanceInBaseCurrency?.toFixed(2) || "0.00"}
          </Text>
        </div>
      )}
      <div className="flex justify-end flex-center gap24">
        <ButtonOutline primary xl>
          <Announcement className="flex flex-center" onClick={notifications}>
            <Icon src={iconAnnouncement} width="23px" alt="" />
            <Text size="rg" fw="md">
              Announcement
            </Text>
            {notificationLists && Object.keys(notificationLists).length ? (
              <Box width="24px" height="24px" bgColor="#F01102" className="flex flex-center justify-center count">
                {Object.keys(notificationLists).length}
              </Box>
            ) : (
              ""
            )}
          </Announcement>
        </ButtonOutline>
        <UserInfo className="flex flex-center pointer" onClick={profile}>
          <Text className="short-name flex flex-center justify-center" size="rg" fw="md" color="color7">
            {user && user.profileImage ? (
              <img src={user.profileImage} alt="" className="profile-pic" />
            ) : user?.firstName ? (
              (user?.firstName[0]).toUpperCase()
            ) : (
              ""
            )}
          </Text>
          <Text className="ml8 mr12" size="rg" fw="md">
            {user && user?.firstName}
          </Text>
        </UserInfo>

        <ButtonSolid primary rg className="flex flex-center justify-center btn-logout" onClick={logout}>
          <IconLogout className="mr8" />
          Log out
        </ButtonSolid>
      </div>
    </Wrapper>
  );
}
