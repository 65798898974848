import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { userLogout, getCookie, setCookie } from "./common";
const LogoutPopup = () => {
  const navigate = useNavigate();

  const [signoutTime, setSignoutTime] = useState(60 * 60 * 1000);
  const [warningTime, setWarningTime] = useState(14 * 60 * 1000);
  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
  
  };
  const logout = () => {
    userLogout();
    navigate("/");
  };

  const destroy = () => {
  
  };
  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime);
    const authToken = getCookie("webAuthToken");
    if (authToken) {
      setCookie(authToken);
    }
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <div></div>;
};
export default LogoutPopup;
