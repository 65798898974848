export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeStorage = (item) => {
  localStorage.removeItem(item);
};

export const setStorage = (key, state) => {
  try {
    const serializeState = JSON.stringify(state);
    localStorage.setItem(key, serializeState);
  } catch (error) {
    console.error("Err: Get Local Storage:", error);
  }
};

export function getUser() {
  // const authToken = getStorage("webAuthToken");
  const authToken = getCookie("webAuthToken");
  return authToken;
}

export function hasProperty(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export function isEmpty(obj) {
  let isEmpty = false;
  const type = typeof obj;
  isEmpty = isEmpty || !obj;
  isEmpty = isEmpty || type === "undefined"; // if it is undefined
  isEmpty = isEmpty || obj === null; // if it is null
  isEmpty = isEmpty || (type === "string" && obj.trim() === ""); // if the string is empty or only have spaces
  isEmpty = isEmpty || obj === false || obj === 0; // if boolean value returns false
  isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
  isEmpty = isEmpty || (type === "object" && Object.keys(obj).length === 0); // if object is empty
  return isEmpty;
}

export const getSelectOptions = (objList, id = "id", name = "name") => {
  const options = [];
  if (isEmpty(objList)) {
    return options;
  }
  if (Array.isArray(objList)) {
    objList.forEach((obj) => {
      const optValue = obj[id];
      const optName = obj[name];
      options.push({ label: optName, value: optValue });
    });
  } else {
    Object.keys(objList).forEach((key) => {
      const optValue = objList[key][id] || key;
      const optName = objList[key][name] || objList[key];
      options.push({ label: optName, value: optValue });
    });
  }

  return options;
};
export const getParams = (formArray) => {
  const params = {};
  formArray.forEach((control) => {
    params[control.name] = control.value;
  });
  return params;
};

export const userLogout = () => {
  removeStorage("webAuthToken");
  removeStorage("loggedin");
  deleteCookie("webAuthToken");
};

export function isLogin() {
  return false;
}

export const paginator = (items, current_page, per_page_items) => {
  let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items.length,
    total_pages: total_pages,
    data: paginatedItems,
  };
};

export const TimeAgo = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (webAuthToken) => {
  var date = new Date();
  date.setTime(date.getTime() + 15 * 60 * 1000);
  var expires = "; expires=" + date.toGMTString();
  document.cookie = "webAuthToken=" + webAuthToken + expires + "; path=/";
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const mapLabelAndValue = async (originalData, key1, key2) => {
  const transformedData = originalData?.map((item) => {
    return {
      value: item[key1],
      label: item[key2],
    };
  });
  return transformedData;
};

export const getOtpValue = (otpInput) => {
  const otp = Object.values(otpInput).join("");
  return otp;
};

export const handleInputFocus = (event) => {
  const { previousElementSibling, nextElementSibling } = event.target;

  if (event.key === "Delete" || event.key === "Backspace") {
    if (previousElementSibling) {
      previousElementSibling.focus();
      previousElementSibling.select();
    }
  } else {
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  }
};

export const trimNumber = (num, from, to) => {
  const str_a = num.toString();
  let a = str_a.slice(from, to);
  return a;
};

export const areNumbersSame = (num1, num2) => {
  if (num1 === num2) return true;
  const parsedNum1 = Number(num1);
  const parsedNum2 = Number(num2);
  if (isNaN(parsedNum1) || isNaN(parsedNum2)) return false;
  return parsedNum1 === parsedNum2;
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};
